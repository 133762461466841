/* eslint-disable camelcase */
/** общий интерфейс для блоков */
import { Block } from '~/interfaces/BlockInterface';
import { SummaryFields } from '../../Summary/Summary.types';

/** Типы пропсов телевидения */
export interface MyTVProps {
  content: MyTVContent;
}

/** интерфейс компонента Телевидение */
export interface MyTVContent extends Block {
  fields: MyTVFields;
}

/** поля блока телевидения */
export interface MyTVFields {
  seriesCode?: string;
  baseSlug?: string;
  transformerSlug?: string;
  channelsByGroups: SubjectWithChannels[];
  packages: ChannelPackage[];
  summary: SummaryFields;
  transformationLimit: number;
}

/** Тематика со списком каналов */
export interface SubjectWithChannels {
  /** Наименование тематики */
  name: string;
  /** Индекс сортировки */
  position?: number;
  /** Массив каналов */
  channels: ChannelFromSubjects[];
}

interface ChannelPackageGeneralProps {
  channelPackName: string;
  isChannelWrapper: boolean;
  isFree: boolean;
  isOrdered: boolean;
  isSale: boolean;
  isTrial: boolean;
  banTrimDate: string;
  unbindDt: string;
  price: number;
  /** Управление просмотром */
  isTimeshiftIncluded: boolean;
}

/** Подключённые каналы и пакеты */
export interface ChannelOrderListProps extends ChannelPackageGeneralProps {
  channelPackId?: number;
  /** id`s подключённых каналов */
  channelIds?: number[];
}

/** Не подключённые каналы и пакеты */
export interface ChannelPackListProps extends ChannelPackageGeneralProps {
  name: string;
  id?: number;
  channelPackChannels?: Channel[];
}

/** пакет телеканалов */
export type ChannelPackage = ChannelPackListProps & ChannelOrderListProps;

interface ChannelGeneralProps {
  id: number;
  name: string;
}

/** Каналы из списка каналов по тематике */
export interface ChannelFromSubjects extends ChannelGeneralProps {
  weburgId: number;
  imgLink: string;
  description: string;
  channelDefinition: string;
  includedToProduct: boolean;
  availablePackets: ChannelPrice[];
  availablePlatforms: string[];
  adultContent: boolean;
  audioStreams: string[];
}

/** Каналы из пакетов */
export interface ChannelFromPackets extends ChannelGeneralProps {
  idRis: number;
  singleChannelPacks: SingleChannelPacksProps[];
  isAccessible: boolean;
  timeshiftDepth: number;
  itvChannelLink: string;
}

export interface SingleChannelPacksProps extends ChannelOrderListProps {
  id: number;
}

/** телеканал */
export type Channel = ChannelFromSubjects &
  ChannelFromPackets &
  SingleChannelPacksProps;

/** стоимость пакета канала */
export interface ChannelPrice {
  name: string;
  price: number | null;
}

/** Доступность */
export enum AvailabilityStates {
  // доступен отдельно от пакета
  AvailableSeparatelyFromThePackage,
  // Доступен только в пакетах
  OnlyAvailableInPackages,
  // Доступен только в 1 пакете
  OnlyAvailableInOnePack,
}

/** Состояния подключения */
export enum ConnectionStates {
  // не подключён
  NotConnected,
  // Подключён отдельно от пакета
  ConnectedSeparatelyFromThePackage,
  // Подключён в пакете
  ConnectedInAPackage,
  // Подключён в продукте
  ConnectedInTheProduct,
  // Подключён в пакете, который подключён в продукте
  ConnectedInPackageInProduct,
  // Оформлен отказ отдельно от пакета
  CancellationIssuedSeparatelyFromThePackage,
  // Оформлен отказ от пакета, в котором находится канал
  ThePackageInWhichTheChannelIsLocatedHasBeenRenounced,
  // Добавлен в трансформер
  AddedToTransformer,
  // Убран из трансформера
  RemovedFromTheTransformer,
  // Добавлен в трансформер в пакете
  AddedToTransformerInPackage,
  // Убран из трансформера в пакете
  RemovedFromTheTransformerInPackage,
}

/** props "Управление просмотром" */
export interface ViewControl {
  isOrdered: boolean;
  banTrimDate: string;
  pendingRejectionDate: string;
  price: number;
  currentTypeCode: string;
}

/** props "Wink" */
export interface WinkInfoResult {
  accountStatus: WinkConnectionStatus;
  ottCode: string;
}

/** enum "Wink" status */
export enum WinkConnectionStatus {
  none = 'none',
  not_activated = 'not_activated',
  activated = 'activated',
  unavailable = 'unavailable',
}

export enum ServiceCode {
  'viewControl',
  'wink',
  'other',
}

export interface ServiceInfoProps {
  name: string;
  code: ServiceCode;
}

/** props трансформера */
export interface TransformerProps {
  orderSum: number;
}

export interface Highlightable {
  highlight?: string;
}

export interface TrialInfoProps {
  isActiveNow: boolean;
  channelPackId: number;
  isAvailable: boolean;
}
