import Script from 'next/script';
import { NextComponentType, NextPageContext } from 'next';
/** api */
import { getCityByIpAddress } from '~/api/api';
/** провайдеры */
import Providers from '~/components/Providers';
/** константы */
import { DEFAULT_CITY } from '~/constants/common';
/** interfaces */
import { City } from '../interfaces/CityInterface';
import { PageProps } from '~/interfaces/Page.interface';

interface AppProps {
  Component: NextComponentType<NextPageContext, unknown, PageProps>;
  pageProps: PageProps;
  suggestedCity: City;
}

export const App = ({
  Component,
  pageProps,
  suggestedCity,
}: AppProps): JSX.Element => {
  if (!pageProps?.providerData) return null;

  return (
    <>
      {process.env.IS_PROD && (
        <Script
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-TTZSQC6');`,
          }}
        />
      )}
      <Script
        strategy="afterInteractive"
        src={process.env.CHAT_JS}
        onLoad={() => {
          function callChat() {
            const params = {
              externalId: null,
              settings: {
                chatFormMode: 0,
                channelsMode: 0,
                editorMode: 1,
                channelsIds: [`${process.env.CHAT_CHANNEL_ID}`],
                buttonSentColor: '#D1327A',
                operatorName: 'Чат',
                theme: {
                  type: 'Default',
                  closeIconUrl: `${process.env.STATIC_SERVER}/static/chat/img/close.png?drop`,
                  attachIconUrl: `${process.env.STATIC_SERVER}/static/chat/img/vect.png?drop1`,
                  vectIconUrl: `${process.env.STATIC_SERVER}/static/chat/img/vect.png?sdrop`,
                },
              },
              formFields: [
                {
                  name: 'ФИО',
                  code: 'FullName',
                  isRequired: false,
                },
                {
                  name: 'Телефон',
                  code: 'Phone',
                  isRequired: true,
                  mask: '+7 (111) 111-11-11',
                },
                {
                  name:
                    '<p title="Отправляя персональные данные, выражаю согласие на&nbsp;обработку персональных данных для целей получения информации, связанной с&nbsp;деятельностью оператора связи &laquo;Планета&raquo; (ООО &laquo;Комтехцентр&raquo;) в&nbsp;объёме:\n' +
                    'Ф.И.О, телефон.">Согласие на&nbsp;обработку персональных данных</p>',
                  code: 'TermsOfUse',
                  isRequired: true,
                },
              ],
            };
            if (window.SherlockChat !== undefined) {
              // api, params, callback
              const chat = new window.SherlockChat(
                `${process.env.API_CHAT}`,
                params,
                `${process.env.CHAT_TOKEN}`,
                null,
              );
              chat.InitWidget(); // для старта виджета
              // chat.Init(); // для старта чата
            } else {
              setTimeout(() => {
                callChat();
              }, 100);
            }
          }
          callChat();
        }}
      />
      <Providers>
        <Component {...pageProps} suggestedCity={suggestedCity} />
      </Providers>
    </>
  );
};

App.getInitialProps = async () => {
  let suggestedCity = DEFAULT_CITY;
  try {
    suggestedCity = await getCityByIpAddress();
  } catch (e) {
    suggestedCity = DEFAULT_CITY;
  }
  return {
    suggestedCity,
  };
};

export default App;
