/** libraries */
import { createContext, useContext } from 'react';
/** model */
import ConnectionModel, { IConnectionStore } from './ConnectionModel';
/** constants */
import { DEFAULT_STATE } from '../constants';

const store = ConnectionModel.create(DEFAULT_STATE);

const ConnectionStoreContext = createContext<IConnectionStore>(store);

const useConnectionStore = (): IConnectionStore => {
  return useContext(ConnectionStoreContext);
};

export default useConnectionStore;
