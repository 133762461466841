import { Block } from '~/interfaces/BlockInterface';

/** поля блока */
export interface TextHTMLFields {
  content: string;
  id?: string;
}

/** контент блока */
export interface TextHTMLContent extends Block {
  fields: TextHTMLFields;
}

/** свойства компонента */
export interface TextHTMLProps {
  content: TextHTMLContent;
}

/** дополнительные идентификаторы для тега button */
export enum HTML_TAGS {
  TAG_TYPE_KEY = 'data-tag-type',
  TARGET = 'target',
  STORIES = 'stories',
  STYLE_TYPE = 'style-type',
  WIZARD_TYPE = 'wizard-type',
  LOGIN = 'login',
  CHAT = 'chat',
}

/** дополнительное название для тега  */
export enum TagType {
  SNOSKA = 'snoska',
  LEADING_TEXT = 'leadingText',
  BTN_HREF = 'btnHref',
}
