import { createContext, useContext } from 'react';
import MakeAuthStore, { IMakeAuthStore } from './MakeAuthModel';

export const store = MakeAuthStore.create({
  serverError: '',
  serverExtensionsError: null,
  password: '',
  login: '',
  errorLogin: false,
  errorPassword: false,
  isLoading: false,
  isVisible: false,
  openSPAfterAuthorizationState: null,
});

export const MakeAuthStoreContext = createContext<IMakeAuthStore>(store);

const useMakeAuthStore = (): IMakeAuthStore => {
  return useContext(MakeAuthStoreContext);
};

export default useMakeAuthStore;
