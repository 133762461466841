/** интерфейсы */
import { Block } from '~/interfaces/BlockInterface';
import { PromoInfoProps } from '~/components/Blocks/Shared/Shared.types';
import { colors } from 'cordis-core-ui-planeta/dist/themes/default';
import { MarketingGroups } from '../ProductSwitcher/interfaces';
import { DefaultImgs } from '../Device/Device.types';
import { SausageProps } from '../../Shared/SummarySausages/types';

/** константы */
import { ConnectionMethod } from '~/constants/common';

/** список кодов устройств */
export enum DeviceCode {
  ROUTER = 'router',
  STB = 'stb',
}

/** список категорий каналов */
export const CHANNELS_CATEGORY = {
  channelCount: 'Всё',
  hdChannelCount: 'HD',
  uhdChannelCount: '4К',
};

/** код маркетинг группы малого бизнеса */
export const BUSINESS_GROUP_CODE = 'smallbusiness';

/** код маркетинг группы малого бизнеса */
export const CLOSED_GROUP_CODE = 'preferential';

/** информация о каналах */
export interface ChannelsInfo {
  channelCount: number;
  hdChannelCount: number;
  uhdChannelCount: number;
}

/** информация об устройстве */
export interface DeviceAbstract {
  name: string;
  discountPercent: number;
  isFree: boolean;
  /* Цена за покупку */
  price: number;
  /* Цена за аренду */
  ownershipPriceOn: number;
  /* Информация об акциях на приобретение устройств */
  promoPriceInfos?: PromoInfoProps[];
}

export interface Device extends DeviceAbstract {
  code: DeviceCode;
}

/** список устройств */
export interface DevicesInfo {
  devices: Device[];
}

export interface Tag {
  name: string;
  code: string;
  sort: number;
}

/** информация о цене */
export interface PriceInfo {
  /** Стоимость за день */
  daily: number;
  /** Стоимость за месяц */
  thirtyDays: number;
  /** Стоимость подключения по оптической сети */
  connection: number;
  /** Ежегодный платёж на годовом продукте */
  annual: number;
  /** Стоимость подключения к продукту */
  migrationCostFirst: number;
  /** Стоимость подключения к продукту */
  promoConnectionInfos?: PromoInfoProps[];
  /** Стоимость перехода с годового медного на годовой оптический продукт */
  upgrade?: number;
  /** Стоимость подключения полугодового продукта */
  halfAnnual?: number;
}

/** акционная вёрстка */
export interface ActionFields {
  /** текст заголовка */
  header?: string;
  /** текст под заголовком */
  text?: string;
  /** ссылка на фон карточки продукта */
  background?: string;
  /** ссылка на картинку */
  icon?: string;
  /** альтернативные размеры изображения */
  iconSize?: { width: number; height: number };
  /** ссылка на вторую картинку */
  secondIcon?: string;
  /** новая цена */
  newPrice?: number;
  /** размер скидки */
  discount?: number;
  /** цвет текста в описании */
  color?: colors;
}

/** поля summary */
export interface SummaryFields {
  tariffId: number;
  seriesId: number;
  seriesName: string;
  seriesCode: string;
  connectionMethod: ConnectionMethod;
  tags: Tag[] | null;
  priceInfo: PriceInfo;
  channelsInfo: ChannelsInfo;
  devicesInfo: DevicesInfo;
  speedBase: number;
  hasTransformerBro: boolean;
  speedBaseText: string;
  marketingText: string;
  marketingGroupCode: string;
  marketingGroupName: string;
  isTransformer: boolean;
  freeVoiceMinutes: number;
  phonesCount: number;
  callPriceMinuteMin: number;
  isAnnual: boolean;
  cities: number[] | null;
  productFeedLink: string;
  transformationLimit: number;
  price?: number;
  features?: SausageProps[];
  mobilesInfo?: MobilesInfo;
  stripColor?: string;
}

export interface MobilesInfo {
  storageBalances: StorageBalances[];
  maxCountMobilePhoneNumberForBind: number;
}

interface StorageBalances {
  storageType: StorageTypes;
  unitType: UnitTypes;
  accrualQuantity: number;
  maxQuantity: number;
  startQuantity: number;
}

export enum StorageTypes {
  OutGoingCall = 'OutgoingCall',
  OutGoingSms = 'OutGoingSms',
  MobileInternet = 'MobileInternet',
  InternationalRoamingMobileInternetZone1 = 'InternationalRoamingMobileInternetZone1',
  InternationalRoamingMobileInternetZone4 = 'InternationalRoamingMobileInternetZone4',
}

export enum PRODUCT_ABBREVIATION {
  GB = 'Гб',
  MINUTES = 'мин',
}

export enum UnitTypes {
  minute = 'Minute',
  gigabyte = 'GigaByte',
  unit = 'Unit',
}

export type ActionType = {
  action?: ActionFields;
};

export interface SummaryContent extends Block {
  fields: SummaryFields & ActionType;
  marketingGroups?: MarketingGroups[];
  defaultImgs: DefaultImgs[];
}

/** интерфейс компоненты Summary */
export interface SummaryProps {
  content: SummaryContent;
  /** Открыть продукт в новой вкладке */
  isLinkInNewTab?: boolean;
  /** Флаг публичности продукта */
  isPrivate?: boolean;
}

/** интерфейс стилевого компонента описания summary */
export interface StyledSummaryDescriptionProps {
  $isBusiness?: boolean;
  $action: ActionFields;
}

export interface ResultConnect {
  isResult: boolean;
  isCorrect: boolean;
  text?: string;
}
