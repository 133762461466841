/** библиотеки */
import { Dispatch, SetStateAction } from 'react';

/** интерфейсы */
import { Block } from '~/interfaces/BlockInterface';
import { OptionProp, PriceTagBackgroundColor } from 'cordis-core-ui-planeta';
import { colors } from 'cordis-core-ui-planeta/dist/themes/default';
import { TabValue } from 'cordis-core-ui-planeta/dist/components/Tabs/Tabs';
import { ImageSizeData } from '../Devices/interfaces';
import { ImageDataProps } from '~/utils/serverImageConverter';
import { TABS } from './DescriptionOfSoftlineSubscription/constants';

/** свойства компонента */
export interface SoftlineContentProps {
  content: SoftlineProps;
}

/** контент блока */
export interface SoftlineProps extends Block {
  fields: SoftlineFieldsProps;
}

/** поля блока */
export interface SoftlineFieldsProps {
  /** список антивирусов */
  features: FeaturesProps;
  /** теги */
  tags: Tags;
  imageSizes: ImageSizeData[];
  /** тип ленты */
  type?: SoftlineType;
  /** код подписки для short блока */
  singleCardId?: number;
  enhancedFields?: SoftlineFields[];
}

/** props списка антивирусов */
export type FeaturesProps = {
  [key in string]: Feature;
};

/** дополнительные свойства объекта */
export interface Feature {
  metricId: number;
  /** название продукта */
  name: string;
  /** Ссылка на изображение */
  image: string;
  /** Индекс сортировки */
  sort: number;
  /** Описание */
  description: Description;
  actions?: Actions;
  operatingSystem?: string;
  footer?: string;
}

/** акции антивирусов */
export type Actions = {
  [key in string]: string;
};

/** Описание подписки */
export interface Description {
  descriptionText?: string;
  descriptionColumns?: DescriptionBlockField[];
  /** Слайдер с преимуществами подписки */
  descriptionSlider?: string[];
}

/** Описание колонок подписки */
export interface DescriptionBlockField {
  header: string;
  lines: string[];
}

export interface Tag {
  name: string;
  tag: string;
  types: number[];
}

/** теги */
export interface Tags {
  [key: string]: Tag;
}

/** поля тега */
interface SoftlineTagFields {
  tag: string;
  types: number[];
}

export interface TagsFields extends SoftlineTagFields {
  value: string;
}

/** Параметры подписки */
export interface SoftlineFieldsData {
  name: string;
  metricId: number;
  price: number;
  /** Срок действия подписки в днях */
  durationDays: number;
  /** Информация о промо-периоде */
  trialInfo: TrialInfo & ConnectedTrialInfo;
  /** Кешбэк */
  chatlingCashback: CashbackProps;
  /** Число лицензий на подписке */
  deviceCount?: number;
}

/** Параметры подключённой подписки */
export interface SoftlineSubscriptionFieldsData extends SoftlineFieldsData {
  statusString: string;
  simId: number;
  /** Дата, с которой возможно отключение подписки */
  banTrimDate: string;
  /** Дата, с которой возможно отключение подписки,
   * учитывается и banTrimDate, и +1 день если banTrimDate уже прошёл)
   * */
  availableUnbindDt: string;
  /** Дата отключения подписки */
  pendingUnbindDate: string;
}

export interface SoftlineFieldsOptions {
  /** имя без спец-символов */
  nameFromMethod?: string;
  key: string;
}

/** поля значений подписки на антивирус */
export type SoftlineFields = SoftlineSubscriptionFieldsData &
  SoftlineFieldsOptions &
  Feature & {
    imageInfo: ImageDataProps;
  };

/** Информация о кешбэке */
export interface CashbackProps {
  cashbackPercent: number;
}

/** Информация о промо-периоде */
interface TrialInfo {
  trialDays: number;
  trialPrice: number;
}

/** Информация о промо-периоде подключённой подписки */
interface ConnectedTrialInfo {
  isEnabled: boolean;
  trialEndDate: string;
}

/** props карточки антивируса */
export interface SoftlineCardProps {
  item: SoftlineFields;
}

/** свойства активного таба */
export interface ActiveTab {
  index: number;
  value: string;
}

/** ответ метода Softline/Softline/CheckBindAvailability */
export interface BindData {
  isAvailable: boolean;
  bindRefuseReason?: string;
  contractStateCode?: string;
  requiredMoneyToBind?: number;
}

export interface SoftlineHookReturnProps {
  isAnnual: boolean;
  isPromo: boolean;
  tabs: TABS[];
  activeTab: ActiveTab;
  onChangeTab: (index: number, value: string | TabValue) => void;
  selectDistributionOS: OptionProp;
  setSelectDistributionOS: Dispatch<SetStateAction<OptionProp>>;
  distributionList: OptionProp[];
  linkToInstallInstructions: string;
  getShipping: (id: number) => Promise<void>;
  login: string;
  password: string;
  activationKey: string;
  formattedPrice: string;
  formattedPricePerMonth: string;
}

export interface BindDataHookReturnProps {
  isActiveSubscription: boolean;
  isAvailable: boolean;
  isInsufficientFunds: boolean;
  isNotServed: boolean;
  backgroundColor: PriceTagBackgroundColor;
  fontColor: colors;
  subscriptionText: string;
  requiredMoneyToBind: number;
  isVacation: boolean;
  isClientBlock: boolean;
  isNovice: boolean;
  isLoadingHook: boolean;
}

/** тип блока с антивирусами */
export enum SoftlineType {
  SHORT = 'short',
  FULL = 'full',
}
